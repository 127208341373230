import { css, Theme, Interpolation } from '@emotion/react'
import React, { HTMLAttributes } from 'react'

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  level?: 1 | 2 | 3 | 4 | 5 | 6
}

const headingStyles: Interpolation<Theme> = theme =>
  css`
    font-size: ${theme.fontSizes.heading3};
    font-weight: ${theme.fontWeights.bold};
    text-align: center;
    white-space: nowrap;

    @media ${theme.breakpoints.md} {
      font-size: ${theme.fontSizes.heading2};
    }
  `

export function Heading({ level = 2, ...props }: HeadingProps) {
  switch (level) {
    case 1:
      return <h1 css={headingStyles} {...props} />
    case 2:
      return <h2 css={headingStyles} {...props} />
    case 3:
      return <h3 css={headingStyles} {...props} />
    case 4:
      return <h4 css={headingStyles} {...props} />
    case 5:
      return <h5 css={headingStyles} {...props} />
    case 6:
    default:
      return <h6 css={headingStyles} {...props} />
  }
}
